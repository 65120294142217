import React from "react"
import Layout from "../components/layout"
import {
  Flex,
  WhiteSection,
  LightBlueSection,
  WhiteBox,
  HeaderBanner,
  GreenBarSection,
  Text,
  FooterBanner,
  ButtonLinkOutline,
  ButtonGreen,
  Images,
  Vis,
  MOBILE_ONLY_CSS_WRAPPER,
  TABLET_ONLY_CSS_WRAPPER,
  DESKTOP_ONLY_CSS_WRAPPER,
  LightBlueSectionNoMargin
} from "../components/common"
import { navTo } from "../utils/navigation"
import styled from "styled-components"
import consentIcon from "../images/you_consent.svg"
import connectIcon from "../images/you_connect.svg"
import collectIcon from "../images/we_collect.svg"
import timeIcon from "../images/clock_icon.svg"
import screenListen from '../utils/screenListener'


const ButtonDelay = (navigateFn) => {
  setTimeout(() => navigateFn(), 300)
}

const SocialAbsolute = styled.div`
  position: absolute;
  ${MOBILE_ONLY_CSS_WRAPPER('top:-28px')}
  ${TABLET_ONLY_CSS_WRAPPER('top:-32px')}
  ${DESKTOP_ONLY_CSS_WRAPPER('top:-56px')}
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      margin: auto;
      object-fit: contain;
      ${MOBILE_ONLY_CSS_WRAPPER('width: 64px;height: 64px;')}
      ${TABLET_ONLY_CSS_WRAPPER('width: 72px;height: 72px;')}
      ${DESKTOP_ONLY_CSS_WRAPPER('width: 112px;height: 112px;')}
      width: 35px;
      height: 35px;
    }
  }
`

const SocialAbsoluteNoBorder = styled.div`
  position: absolute;
  ${MOBILE_ONLY_CSS_WRAPPER('top:-28px')}
  ${TABLET_ONLY_CSS_WRAPPER('top:-32px')}
  ${DESKTOP_ONLY_CSS_WRAPPER('top:-32px')}
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      margin: auto;
      ${MOBILE_ONLY_CSS_WRAPPER('width: 64x;height: 64px;')}
      ${TABLET_ONLY_CSS_WRAPPER('width: 64px;height: 64px;')}
      ${DESKTOP_ONLY_CSS_WRAPPER('width: 64px;height: 64px;')}
    }
  }
`

const howItWork = "How it works"
const howItWorkDesc = `If you have accounts with CDR-ready banks, we can connect and collect your data with your consent. We'll then make it available as a PDF for you to download`

const journey = [
  { icon: consentIcon, head: 'You Consent', body: 'We\'ll collect your data only with your permission' },
  { icon: connectIcon, head: 'You Connect', body: 'Link your banks with us through an encrypted and secured service' },
  { icon: collectIcon, head: 'We Collect', body: 'You\'ll be able to download all your data as a PDF' },
]

const WhiteSectionMobile = () => {
  const { width } = screenListen()
  let basePadding = 20
  if (width > 375 && width) {
    const scale = width * 0.08
    basePadding = basePadding + scale
  }
  return (
    <WhiteSection flex="col" style={{ margin: '29px 10px 0px 10px', padding: `0px ${basePadding}px 0px {basePadding}px` }}>
      <Text.H1 style={{ marginTop: '0px', color: '#373737', fontFamily: 'Arial', fontWeight: 'bold' }}>{howItWork}</Text.H1>
      <Flex className="col" style={{ padding: '0px 20px', marginBottom: '25px' }}>
        <Images.HowItWorksHero />
      </Flex>
      <GreenBarSection style={{ color: '#373737', fontFamily: 'Arial' }}>{howItWorkDesc}</GreenBarSection>
      <WhiteSection flex="row" style={{ justifyContent: 'center', marginTop: '70px', padding: "0px 0px", width: '424px' }}>
        <ButtonLinkOutline
          style={{ padding: "0 30px", marginRight: '21px', minWidth: "50%" }}
          rel="noreferrer noopener"
          onClick={() => ButtonDelay(navTo.introduction_1)}
        >
          Back
        </ButtonLinkOutline>
        <ButtonGreen
          style={{ padding: "0 30px", minWidth: "50%" }}
          onClick={() => ButtonDelay(navTo.letsgetyouverified_3)}>
          Next
        </ButtonGreen>
      </WhiteSection>
    </WhiteSection>
  )
}

const WhiteSectionTablet = () => {
  return (
    <WhiteSection flex="col" style={{ margin: '0px 35px 0px 38px' }}>
      <Text.H1 style={{ color: '#373737', fontFamily: 'Arial', fontWeight: 'bold' }}>{howItWork}</Text.H1>
      <GreenBarSection style={{ color: '#373737', fontFamily: 'Arial', maxWidth: '470px' }}>{howItWorkDesc}</GreenBarSection>
      <Flex className="col" style={{ padding: '0px 100px' }}>
        <Images.HowItWorksHero />
      </Flex>
      <WhiteSection flex="row" style={{ justifyContent: 'center', marginTop: "70px", padding: '0px 50px' }}>
        <ButtonLinkOutline
          style={{ padding: "0 30px", marginRight: '21px', minWidth: "50%" }}
          rel="noreferrer noopener"
          onClick={() => ButtonDelay(navTo.introduction_1)}
        >
          Back
        </ButtonLinkOutline>
        <ButtonGreen
          style={{ padding: "0 30px", minWidth: "50%" }}
          onClick={() => ButtonDelay(navTo.letsgetyouverified_3)}>
          Next
        </ButtonGreen>
      </WhiteSection>
    </WhiteSection>
  )
}

const WhiteSectionDesktop = () => {
  let baseMarginTop = 90

  return (
    <WhiteSection flex="row" style={{ margin: '52px 50px 0px 100px' }}>
      <WhiteSection flex="col w50" style={{ padding: '0px' }}>
        <WhiteSection flex="col nogrow">
          <Text.H1 flex="nogrow" style={{ color: '#373737', fontFamily: 'Arial', fontWeight: 'bold' }}>
            {howItWork}
          </Text.H1>
          <GreenBarSection style={{ overflowWrap: 'break-word', color: '#373737', fontFamily: 'Arial' }}>
            {howItWorkDesc}
          </GreenBarSection>
        </WhiteSection>
        <WhiteSection flex="row" style={{ marginTop: `${baseMarginTop}px`, padding: "0px 0px", width: '424px' }}>
          <ButtonLinkOutline
            style={{ padding: "0 30px", marginRight: '21px', minWidth: "200px" }}
            rel="noreferrer noopener"
            onClick={() => ButtonDelay(navTo.introduction_1)}
          >
            Back
          </ButtonLinkOutline>
          <ButtonGreen
            style={{ padding: "0 30px", minWidth: "200px" }}
            onClick={() => ButtonDelay(navTo.letsgetyouverified_3)}>
            Next
          </ButtonGreen>
        </WhiteSection>
      </WhiteSection>
      <Flex flex="w50 col vcenter hcenter" >
        <WhiteSection flex="row" style={{ width: '100%' }}>
          <Images.HowItWorksHero />
        </WhiteSection>
      </Flex>
    </WhiteSection>
  )
}
const MidSectionMobile = () => {
  const { width } = screenListen()
  let basePadding = 5
  if (width > 375 && width) {
    const scale = width * 0.07
    basePadding = basePadding + scale
  }
  return (
    <LightBlueSection flex="col" style={{ padding: `0px 5px` }}>
      {" "}
      {journey.map(({ icon, head, body }) => {
        return (
          <WhiteBox
            key={icon}
            flex="col center text-center shadow"
            style={{ margin: "30px 0px 20px 0px", position: "relative" }}
          >
            <Text.Semi24 style={{ color: '#373737', fontFamily: 'Arial', fontWeight: 'bold' }}>{head}</Text.Semi24>
            <Text.Karbon20 style={{ color: '#373737', fontFamily: 'Arial' }}>{body}</Text.Karbon20>
            <SocialAbsolute>
              <div>
                <img alt='' src={icon} />
              </div>
            </SocialAbsolute>
          </WhiteBox>
        )
      })}
    </LightBlueSection>
  )
}

const MidSectionTablet = () => {
  const { width } = screenListen()
  let basePadding = 90
  if (width > 992 && width) {
    const scale = width * 0.05
    basePadding = basePadding + scale
  }

  return (
    <LightBlueSection flex="col" style={{ padding: `0px ${basePadding}px` }}>
      {" "}
      {journey.map(({ icon, head, body }) => {
        return (
          <WhiteBox key={icon}
            flex="col center text-center shadow w100"
            style={{ margin: "50px 0px 20px 0px", paddingBottom: '30px', position: "relative" }}
          >
            <Text.Semi24 style={{ color: '#373737', fontFamily: 'Arial', fontWeight: 'bold' }}>{head}</Text.Semi24>
            <Text.Karbon20 style={{ color: '#373737', fontFamily: 'Arial' }}>{body}</Text.Karbon20>
            <SocialAbsolute>
              <div>
                <img alt='' src={icon} />
              </div>
            </SocialAbsolute>
          </WhiteBox>
        )
      })}
    </LightBlueSection>
  )
}
const MidSectionDesktop = () => {
  const { width } = screenListen()
  let basePadding = 10
  if (width > 992 && width) {

    let scale
    if (width > 1980) {
      scale = width * 0.06
    } else {
      scale = width * 0.1
    }
    basePadding = basePadding + scale
  }
  return (
    <LightBlueSectionNoMargin flex="row" style={{ justifyContent: "space-evenly", padding: `30px ${basePadding}px` }}>
      {" "}
      {journey.map(({ icon, head, body }) => {
        return (
          <WhiteBox key={icon}
            flex="col hcenter text-center shadow w50"
            style={{ margin: "50px 10px 10px 10px", paddingBottom: '58px', position: "relative" }}
          >
            <Text.Semi24 style={{ color: '#373737', fontFamily: 'Arial', fontWeight: 'bold' }}>{head}</Text.Semi24>
            <Text.Karbon20 style={{ color: '#373737', fontFamily: 'Arial', width: '75%' }}>{body}</Text.Karbon20>
            <SocialAbsolute>
              <div>
                <img alt='' src={icon} />
              </div>
            </SocialAbsolute>
          </WhiteBox>
        )
      })}
    </LightBlueSectionNoMargin>)
}

const Spacer = styled.div`
${MOBILE_ONLY_CSS_WRAPPER('margin:10px 0;')}
${TABLET_ONLY_CSS_WRAPPER('margin:20px 0 10px 0;')}
${DESKTOP_ONLY_CSS_WRAPPER('margin:50px 0 20px 0;')}
`
const Spacer2 = styled.div`
${MOBILE_ONLY_CSS_WRAPPER('margin-top:10px;margin-bottom:31px;')}
${TABLET_ONLY_CSS_WRAPPER('margin-top:10px;margin-bottom:43px;')}
${DESKTOP_ONLY_CSS_WRAPPER('margin-top:10px;margin-bottom:80px;')}
`

const HowItWorksPage = () => {
  return (
    <Layout.ColumnLayout title={howItWork}>
      <HeaderBanner />
      <Vis.MobileOnly>
        <WhiteSectionMobile />
      </Vis.MobileOnly>
      <Vis.TabletOnly>
        <WhiteSectionTablet />
      </Vis.TabletOnly>
      <Vis.DesktopOnly>
        <WhiteSectionDesktop />
      </Vis.DesktopOnly>

      <LightBlueSectionNoMargin
        flex="col text-center"
        style={{ paddingBottom: '70px', marginTop: '50px' }}
      >
        <Spacer>
          <Text.H2 style={{ justifyContent: "center", color: '#373737', fontFamily: 'Arial', fontWeight: 'bold' }}>
            Discover our 3 step journey
          </Text.H2>8
        </Spacer>
        <Vis.MobileOnly>
          <MidSectionMobile />
        </Vis.MobileOnly>
        <Vis.TabletOnly>
          <MidSectionTablet />
        </Vis.TabletOnly>
        <Vis.DesktopOnly>
          <MidSectionDesktop />
        </Vis.DesktopOnly>
      </LightBlueSectionNoMargin>

      <WhiteSection
        flex="col center text-center"
        style={{ position: "relative", paddingTop: "30px" }}
      >
        <SocialAbsoluteNoBorder>
          <div>
            <img alt='' src={timeIcon} />
          </div>
        </SocialAbsoluteNoBorder>
        <Text.Karbon20Bold style={{ color: '#373737', fontFamily: 'Arial', fontWeight: 'bold', marginBottom: "100px" }}>
          This should only take a few minutes
        </Text.Karbon20Bold>
      </WhiteSection>
      {/* <WhiteSection flex="row" style={{ marginTop: "50px" }}>
        <Buttons.BackNextButtons
          backOnClick={() => ButtonDelay(navTo.introduction_1)}
          nextOnClick={() => ButtonDelay(navTo.letsgetyouverified_3)}
        />
      </WhiteSection> */}

      <FooterBanner />
    </Layout.ColumnLayout>
  )
}

export default HowItWorksPage
